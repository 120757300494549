import React from "react";
import Layout from "../components/layout"
import { library } from '@fortawesome/fontawesome-svg-core'
import {
  faLinkedinIn,
  faGithub,
  faTwitter
} from '@fortawesome/free-brands-svg-icons'
import {
  faEnvelope
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

library.add(faLinkedinIn, faGithub, faTwitter, faEnvelope)

const EventsPage = ({ data }) => (
    <Layout>
        <p>
          <h3>Contact</h3>
        </p>
        <p>
          <h4>
            <a href="mailto:info@christiaanrudolfs.com">
              <FontAwesomeIcon icon={['fas', 'envelope']} color="#5c8f94"/>
            </a>
          </h4>
          {' (Email)'}
        </p>
        <p>
          <h4>
            <a href="https://www.linkedin.com/in/crudolfs" target="_blank" rel="noopener noreferrer">
              <FontAwesomeIcon icon={['fab', 'linkedin-in']} color="#5c8f94"/>
            </a>
          </h4>
          {' (LinkedIn)'}
        </p>
        <p>
          <h4>
            <a href="https://github.com/crudolfs" target="_blank" rel="noopener noreferrer">
              <FontAwesomeIcon icon={['fab', 'github']} color="#5c8f94" />
            </a>
          </h4>
          {' (Github)'}
        </p>
        <p>
          <h4>
            <a href="https://twitter.com/crudolfs" target="_blank" rel="noopener noreferrer">
              <FontAwesomeIcon icon={['fab', 'twitter']} color="#5c8f94" />
            </a>
          </h4>
          {' (Twitter)'}
        </p>
    </Layout>
)

export default EventsPage;
